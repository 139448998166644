body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Main feed container */
.image-feed {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
}

/* Post container */
.post-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Profile header (profile pic and username) */
.profile-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0;
}

.profile-pic {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.username {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

/* Blob image container */
.blob-container {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 20px 0;
}

.blob-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%; /* Circular blob shape */
  clip-path: circle(50% at 50% 50%); /* Ensures perfect circular crop */
  transition: transform 0.5s ease;
}

.blob-container:hover .blob-image {
  transform: scale(1.05);
}

/* Post details (username and caption) */
.post-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.caption {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.mirror-frame {
  background-image: url("./mirror.png"); /* Use the fancy mirror frame */
  background-size: contain;
  background-repeat: no-repeat;
  width: 450px;
  height: 550px;
  position: relative;
}

.mirror-glass {
  position: absolute;
  top: 14.5%;
  left: 15.5%;
  width: 68%;
  height: 68%;
  clip-path: ellipse(50% 50% at 50% 50%); /* This shapes the glass as an oval */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scanner {
  aspect-ratio: 1;
}

.scanner video {
  object-fit: contain;
}

.scanner svg {
  max-width: 100%;
  max-height: 100%;
}

.mirror-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
